<template>
  <div>
  <b-button size="sm" variant="success" @click="openForm()">Criar Imóvel</b-button>
  <b-row>
    <b-col cols="12">
      <company-immobile-list />
    </b-col>
  </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import CompanyImmobileList from './CompanyImmobileListView.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    CompanyImmobileList,
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  methods: {
    openForm() {
      this.$router.push({ path: '/company/immobile/register' })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
body {
    background-color: #f8f8f8 !important;
}
</style>
